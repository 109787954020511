import * as React from 'react';
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';
import commonMessageValues from '../templates/formatjs-message-values';

const AboutPage = () => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.about_us}`,
    message: <FormattedMessage id="message_about_us" values={commonMessageValues}/>
  }];

  return (<TextPage locale={intl.locale} title={`${messages.about_us}`} textBlocks={textBlocks}/>);
};

export default AboutPage;